import React, { useState } from "react";
import "assets/styles/pages/sidebar.scss";
import { Link, navigate } from "gatsby";
import treeicon from "assets/images/admin/tree-icon.png";
import breadcumbarrow from "assets/images/admin/breadcumb-arrow-left.png";
import { useForm } from "react-hook-form";
import { Modal, Spinner } from "react-bootstrap";
import "assets/styles/pages/dashboardstatus.scss";
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";
import "assets/styles/pages/sidebar.scss";
import "assets/styles/pages/dashboardstatus.scss";
import "react-pro-sidebar/dist/css/styles.css";
import "assets/styles/pages/customheader.scss";
import { toast, ToastContainer } from "react-toastify";
import AdminService from "services/admin/admin.service";
import PlantationListDetails from "services/plantation/plantation.service";
import { useQueryParam, NumberParam } from "use-query-params";
import { useEffect } from "react";

export default function UploadgreenAssetsKml() {
  const [adminId] = useQueryParam("id", NumberParam);

  const { handleSubmit } = useForm();

  const adminApi = new AdminService();
  const ds = new PlantationListDetails();

  const [filesList, setFilesList] = useState([]);

  const [signedList, setSignedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [kmlUrl, setKmlUrl] = useState();
  const [showError, showShowError] = useState(false);

  const fileLoad = async (e) => {
    const file = e.target.files[0];

    const name = e.target.files[0].name;
    const lastDot = name.lastIndexOf(".");

    const fileName = name.substring(0, lastDot);
    const filetype = "text";
    const fileExtension = "kml";

    let kmlData = {
      fileName: fileName,
      fileExtension: fileExtension,
      fileType: filetype,
    };

    const filesData = [...filesList, file];
    const signedData = [...signedList, kmlData];
    setFilesList(filesData);
    setSignedList(signedData);
    setLoading(false);
  };

  const finalCall = (kmlFile) => {
    const kmlUploadPayload = {
      kmlFileUrl: kmlFile?.[0]?.kmlFileUrl,
      kmlFileName: kmlFile?.[0]?.kmlFileName,
    };

    adminApi
      .uploadGreenAssetKml(kmlUploadPayload, adminId)
      .then((res) => {
        setLoading(false);
        toast.success("KML Uploaded successfully");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const kmlUploadCall = async (kmlData) => {
    setLoading(true);
    let pathkml = [];
    const resultsData = await kmlData.map((item, index) => {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: filesList[index],
        redirect: "follow",
      };
      setLoading(true);
      return fetch(item.signedUrl, requestOptions).then(() => {
        var obj = {};
        var filenameobj = {};
        obj["kmlFileUrl"] = kmlData[index].objectPath;
        obj["kmlFileName"] = kmlData[index].fileName;

        pathkml.push({ ...obj, ...filenameobj });
      });
    });
    return Promise.all(resultsData)
      .then(() => {
        finalCall(pathkml);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const saveKml = async () => {
    setLoading(true);
    const kmlResponse = await ds.imagesSignedUrl({
      signedUrlDetails: signedList,
    });
    if (kmlResponse !== undefined) {
      if (kmlResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }

    await kmlUploadCall(kmlResponse.data.signedUrlDetails);
  };

  const kmlUploadApiCall = () => {
    setLoading(true);
    saveKml();
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const onSubmit = async () => {
    if (urlPatternValidation(kmlUrl) === false) {
      showShowError(true);
    } else {
      postKmlUrl();
    }
  };

  const onchangeKmlbox = (e) => {
    setKmlUrl(e.target.value);
  };

  const postKmlUrl = () => {
    const kmlUploadPayload = {
      kmlFileUrl: kmlUrl,
      kmlFileName: "Green Asset Forests KML",
    };

    adminApi
      .uploadGreenAssetKml(kmlUploadPayload, adminId)
      .then((res) => {
        setLoading(false);
        toast.success("KML Uploaded successfully");
        setTimeout(() => {
          navigate("/admin/greenassetsstatus");
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  useEffect(() => {
    showShowError(false);
  }, [kmlUrl]);

  return (
    <div>
      <div className="sidebar-content-wrapper">
        <div className="admin-breadcumb-item">
          <span>
            <Link className="" to="/admin/dashboardview/">
              <img src={treeicon} alt="treeicon" />
            </Link>
          </span>

          <span>
            <img src={breadcumbarrow} alt="arrowicon" />
          </span>

          <h3> Upload Green Asset </h3>
        </div>

        <div className="white-iitiative-wrapper">
          <div className="" style={{ paddingLeft: "14px" }}>
            <h3 className="sub-heading-title">ADD KML</h3>
          </div>
          <div className="register-plantation">
            <div className="container">
              <div className="row">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="fc-wrapper">
                        <label htmlFor="name" className="form-label">
                          ADD KML <i className="hastric-color">*</i>
                        </label>
                        <textarea
                          type="textarea"
                          name="upload"
                          className="form-control "
                          id="upload"
                          placeholder=""
                          // {...register("projectDescription", {
                          //   required: "KML is required",
                          // })}
                          onChange={(e) => {
                            onchangeKmlbox(e);
                          }}
                        />
                        {showError && (
                          <span className="form-error">
                            {"URL entered is Invalid"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Link
                      to="/admin/greenassetsstatus"
                      className="btn btn-cancel mg-right10 margin-btm-10"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-viewmore save-width"
                      // onClick={() => kmlUploadApiCall()}
                      // onClick={() => postKmlUrl()}
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </div>
  );
}
