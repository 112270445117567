import React from "react";

import AdminLayout from "../../adminlayout/index";

import SEO from "components/seo/index";
// import Side from 'layout/sidebaritem';

import UploadgreenAssetsKml from "components/admin/greenAsset/uploadgreenassetkmlpage";

const uploadGreenAssetKml = ({ location }) => {
  const { state } = location;
  // console.log("{ location }", state);
  return (
    <>
      <SEO title="Forests By Heartfulness admin" />
      <AdminLayout>
        <div>
          <UploadgreenAssetsKml assetId={state?.assetId} />
        </div>
      </AdminLayout>
    </>
  );
};

export default uploadGreenAssetKml;
